import {  AfterContentChecked, Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data/data.service';
import { apiResultFormat, categoryList, tourList } from '../shared/models/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked  {
  fbslides: any[] = new Array(3).fill({id: -1, src: '', title: '', subtitle: ''}); 
  bannerslides: any[] = new Array(4).fill({id: -1, src: '', title: '', subtitle: ''});
  slides: any[] = [];

  public categoryList: Array<categoryList> = [];
  public categoryListSub: Array<categoryList> = [];
  public filterListSub: Array<categoryList> = [];
  public TourList: Array<tourList> = [];
  lastParentName= '歐洲';
  FilterValue = 'TOP';
  parentId = '';
  isRun = false;
  totalTour=0;
  catContent='';
  bannerSrc='./assets/img/slider/';
  bannerTitle='/itinerary?itinCode=';


 constructor (public data: DataService, categoryData: DataService) {

 } 



public ngOnInit(): void  {


  console.log('slides before:', this.slides);
  this.getBanner();

  this.getCategoryListTop();

  this.getTour();

//  this.showNotice();

}

  private getBanner() {

    // type=2, for banner in home large banner slider
    this.slides = [];
    let bannerType=2;
    let bannerSrc='';
    let bannerTitle='';

    this.data.getBanner(bannerType).subscribe((bannerdata: any) => {
      console.log("bannerdata:",bannerdata);
      for (let i=0; i<bannerdata.length; i++) {
        bannerSrc='./assets/img/slider/'+bannerdata[i].bannerLink;
        bannerTitle='/itinerary?itinCode='+bannerdata[i].tourCode;
        this.slides.push({id: i, src: bannerSrc, title: bannerTitle, subtitle: bannerdata[i].bannerUrl});
      }
      console.log("slides:",this.slides);
      
    });

    // type=3, for facebook banner, wechat, fake banner
    this.fbslides = [];
    bannerType=3;
    bannerSrc='';
    bannerTitle='';

    this.data.getBanner(bannerType).subscribe((bannerdata: any) => {
      console.log("bannerdata:",bannerdata);
      for (let i=0; i<bannerdata.length; i++) {
        bannerSrc='./assets/img/slider/'+bannerdata[i].bannerLink;
        bannerTitle='./assets/img/slider/'+bannerdata[i].bannerLink;
        this.fbslides.push({id: i, src: bannerSrc, title: bannerTitle, subtitle: bannerdata[i].bannerUrl});
      }
      console.log("fbslides:",this.fbslides);
      
    });

    // type=1, for poster, newspaper banner
    this.bannerslides= [];
    bannerType=1;
    bannerSrc='';
    bannerTitle='';

    this.data.getBanner(bannerType).subscribe((bannerdata: any) => {
      console.log("bannerdata:",bannerdata);
      for (let i=0; i<bannerdata.length; i++) {
        bannerSrc='./assets/img/slider/'+bannerdata[i].bannerLink;
        bannerTitle='./assets/img/slider/'+bannerdata[i].bannerLink;
        this.bannerslides.push({id: i, src: bannerSrc, title: bannerTitle, subtitle: bannerdata[i].bannerUrl});
      }
      console.log("bannerslides:",this.bannerslides);
      
    });


  }

ngAfterContentChecked(): void {
  if (!this.isRun) {
    this.setMenuItems();
    const menuItems = document.querySelectorAll(".as_menu_item"); 
    if (menuItems.length > 0) 
      this.isRun= true;
  }


}


  private getTour() {
    let location=9999;
    let catType='all';
    let itinCode='any';
      this.data.getTourByCategory(location,catType,itinCode).subscribe((tourdata: any) => {
    //        this.totalCategoryData = categorydata.totalData;
//               console.log("sub data:", categorydata);
              this.totalTour = tourdata.length;
              for (let i=0; i<tourdata.length; i++) {
                  this.TourList.push(tourdata[i]);
                  this.catContent=tourdata[i].catContent;
                  this.data.getImageList(tourdata[i].tourCode ).subscribe((tourdata: any) => {
                    let totalImage = tourdata.length;
                    console.log('tourImage:', tourdata[0].tourImageName);
                    this.TourList[i].imageName=tourdata[0].tourImageName;
                });  
        
              };
          });
  }


  public  setMenuItems() {
      // Setup menu listener, listen click and open submenu 
      const menuItems = document.querySelectorAll(".as_menu_item");
      console.log ("Menu items:", menuItems);
      menuItems.forEach(function (menuItem) {
        menuItem.addEventListener("click", function () {
          menuItem.classList.toggle("active");
      
          var submenu = menuItem.querySelectorAll<HTMLElement>(".as_sub_menu");
          console.log("Sub menu:", submenu);
          if (submenu) {
            submenu[0].style.display = menuItem.classList.contains("active") ? "block" : "none";
          }
      
          menuItems.forEach(function (item) {
            if (item !== menuItem) {
              item.classList.remove("active");
              var otherSubmenu = item.querySelectorAll<HTMLElement>(".as_sub_menu");
              if (otherSubmenu) {
                otherSubmenu[0].style.display = "none";
              }
            }
          });
        });
      });


      // If using mobile, hide the widget_title, otherwise show the widget title 
      if (window.innerWidth <= 576) {
        // Footer Widget
        const widgetTitles = document.querySelectorAll<HTMLElement>('.widget_title');
        var index =0, length = widgetTitles.length;
        
        for (; index < length; index++) {
          widgetTitles[index].style.display="none";
        }
          
      } else {
        const widgetTitles = document.querySelectorAll<HTMLElement>('.widget_title');
        var index =0, length = widgetTitles.length;
        
        for (; index < length; index++) {
          widgetTitles[index].style.display="block";
        }

      }
      
  }

  private getCategoryListTop() {

    this.data.getCategoryListSub().subscribe((categorydata: any) => {
      //        this.totalCategoryData = categorydata.totalData;
 //               console.log("sub data:", categorydata);
                for (let i=0; i<categorydata.length; i++) {
 //                 console.log("CategoryData:"+categorydata[i]);
		    if(categorydata[i].categoryId !== "187")
                    this.categoryListSub.push(categorydata[i]);
      
          
                };
            });
      
       

      // Get Category List 
      this.data.getCategoryList().subscribe((categorydata: any) => {
//        this.totalCategoryData = categorydata.totalData;

          for (let i=0; i<categorydata.length; i++) {
//            console.log("CategoryData:"+categorydata[i]);
              if(categorydata[i].categoryId !== "186")
	      this.categoryList.push(categorydata[i]);
          };
      });



    }


  groupCategory (item: categoryList) : boolean {
    console.log("Last Parent Name:",this.lastParentName)
    if (item.parentName !== this.lastParentName) {
      this.lastParentName = item.parentName;
      return true;
    } else {
      return false;
    }
  }

  storeParentId ( item: categoryList ) : boolean {
    this.parentId = item.categoryId;
  //  console.log("This parentId:",this.parentId);

    this.filterListSub = this.categoryListSub
    .slice()
    .filter(e => e.parentId === this.parentId )

    return true;
  }

  searchTour() {
    console.log("Search clicked!");
    var searchTour = document.getElementById('searchTour') as HTMLInputElement;
    console.log('searchTour:', searchTour.value);
    window.location.href='tour?loc='+searchTour.value+'&type=search';

  }

  loadItineraryPage(tourCode: string): void {
    window.location.href='/itinerary?itinCode='+tourCode;
  }

  openPDF(brochureLink: string ) {
    //     let pdflink = "/assets/static/"+tourCode+".pdf";
         let pdflink = brochureLink;
         console.log("PDF LINK:",pdflink);
         window.open(pdflink, '_blank');
 }

 showNotice() {
  Swal.fire({
    width: '800',
    html:
      `<a href="/lecture" style="margin-left:10px"><img src="./assets/img/slider/lecture3.jpg" alt="notice" width="100%" height="100%"/></a>`,

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i>關閉',
    confirmButtonAriaLabel: 'Thumbs up, great!',
    cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    cancelButtonAriaLabel: 'Thumbs down',
  });
}

dlPDF(brochureLink: string): void {
  let checkpdf = brochureLink.split(",");
  var pdffile= [];
  let pdflink="";
   if (checkpdf.length > 1) {
       pdffile = checkpdf[0].split("/");
     pdflink = "/assets/static/"+pdffile[pdffile.length-1];
     window.open(pdflink);
   } else {
     let pdffile = brochureLink.split("/");
     pdflink = "/assets/static/"+pdffile[pdffile.length-1];
     window.open(pdflink);
   }
}

}
